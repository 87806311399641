import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.defaults({
  toggleActions: "restart play resume pause",
  markers: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

gsap.from("#navigation-slide-toggle", {
  x: 100,
});

//------------------------------------------------------//
// Animation ELEMENT 
//------------------------------------------------------//
document.querySelectorAll(".kernwaarden-container").forEach(function (container) {

  const item = container.querySelectorAll(".item-kenmerken");
  const textPath = container.querySelectorAll('.masterTextPath');

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      // scrub: 0.5, // Add dda small delay of scrolling and animation. `true` is direct
      start: "top bottom-=20%", // Can be top, center, bottom 
      end: "100% bottom-=25%", // Can be top, center, bottom 
      // pin: true,
    }
  });
  timeline.from(textPath, {
    attr: { startOffset: "100%" },
    ease: "power1"
  });
  timeline.from(textPath, {
    duration: 1,
    letterSpacing: 100,
    // attr: { textLength: 1000 }
  }, '<');
  // timeline.reversed(true)
});
// END Animation ELEMENT -------------------------------------//

//------------------------------------------------------//
// ✨ Parallax elements
//------------------------------------------------------//
document.querySelectorAll(".parallex").forEach(function (container) {
  var random = gsap.utils.random(['20vh', '10vh', '40vh']);
  const item = container.querySelectorAll("svg");
  let start = container.dataset.start ? container.dataset.start : 'center';
  let end = container.dataset.end ? container.dataset.end : 'center';
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container, // What element triggers the scroll
      scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
      start: `top ${start}`, // Can be top, center, bottom
      end: `100% ${end}`, // Can be top, center, bottom
      // pin: true,
    }
  });
  timeline.from(item, {
    // y: '-20vh',
    // y: "random([20vh, 10vh, 40vh])",
    y: function (index, target, targets) {
      if (target.dataset.offset) {
        return target.dataset.offset;
      } else {
        return random;
      }
    }
  });

});
// END ✨ Parallax elements -------------------------------------//